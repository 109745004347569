
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import Footer from "@/components/layout/Footer.vue";
import useI18nAffidaty from "@/composables/I18nAffidatyApi";

export default defineComponent({
	name: "guide",
	components: {
		IonContent,
		IonPage,
		Footer
	},
  setup() {
    const { t, getLangWithoutRegion } = useI18nAffidaty()
    const lang = getLangWithoutRegion()
    const termsCondUrl = `https://legal.affidaty.io/${lang}/termini-e-condizioni-user-e-vote/`
    const privacyUrl = `https://legal.affidaty.io/${lang}/privacy-e-vote/`
    return {
      t,
      termsCondUrl,
      privacyUrl
    }
  }
});
