<template>
	<ion-page>
		<ion-content :fullscreen="true">
      <h2 class="ion-text-center mt-30">
        <ion-text color="primary">
          <b>Info</b>
        </ion-text>
      </h2>
			<section class="ion-margin login-section">
        <p class="mb-0"><b>{{t("Terms and conditions e-Vote module")}}</b></p>
        <p><a :href="termsCondUrl" target="_blank">
          <ion-button class="ion-no-margin mt-5" size="small" shape="round">Link</ion-button>
        </a></p>
        <p class="mb-0"><b>{{t("E-Vote privacy policy info")}}</b></p>
        <p>
          <a color="primary" :href="privacyUrl" target="_blank">
            <ion-button class="ion-no-margin mt-5" size="small" shape="round">Link</ion-button>
          </a>
        </p>

      </section>
		</ion-content>
		<Footer></Footer>
	</ion-page>
</template>

<script lang="ts">
import { IonContent, IonPage } from "@ionic/vue";
import { defineComponent } from "vue";
import Footer from "@/components/layout/Footer.vue";
import useI18nAffidaty from "@/composables/I18nAffidatyApi";

export default defineComponent({
	name: "guide",
	components: {
		IonContent,
		IonPage,
		Footer
	},
  setup() {
    const { t, getLangWithoutRegion } = useI18nAffidaty()
    const lang = getLangWithoutRegion()
    const termsCondUrl = `https://legal.affidaty.io/${lang}/termini-e-condizioni-user-e-vote/`
    const privacyUrl = `https://legal.affidaty.io/${lang}/privacy-e-vote/`
    return {
      t,
      termsCondUrl,
      privacyUrl
    }
  }
});
</script>

<style scoped>
</style>